import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">



<path d="M1262 1737 c-225 -225 -234 -238 -194 -287 l17 -21 43 39 c92 85 241
142 372 142 129 0 280 -57 369 -139 l41 -38 20 22 c46 49 36 64 -188 287 -177
178 -212 208 -237 208 -26 0 -61 -31 -243 -213z m458 -18 c121 -121 190 -196
190 -209 0 -11 -2 -20 -5 -20 -2 0 -39 23 -81 50 -108 70 -192 95 -324 95
-125 0 -211 -23 -309 -84 -36 -22 -68 -38 -73 -35 -5 3 -7 0 -6 -7 2 -6 -3
-14 -9 -16 -8 -3 -13 3 -13 14 0 10 86 104 198 215 164 163 201 196 220 192
11 -4 107 -91 212 -195z"/>
<path d="M1426 1509 c-73 -11 -154 -47 -224 -101 l-53 -41 27 -28 27 -28 40
35 c22 20 65 48 96 63 49 23 68 26 161 26 92 0 112 -3 160 -26 31 -14 73 -41
94 -59 l37 -33 27 26 26 27 -34 29 c-105 89 -247 130 -384 110z"/>
<path d="M1399 1337 c-44 -17 -102 -52 -119 -72 -11 -13 -9 -20 10 -40 l24
-25 25 24 c80 76 239 78 318 4 l23 -21 25 23 c23 21 24 25 10 40 -9 10 -39 30
-68 45 -42 24 -67 29 -132 32 -50 2 -93 -2 -116 -10z"/>
<path d="M1434 1181 c-62 -28 -63 -38 -11 -88 62 -59 88 -58 150 5 53 54 52
58 -16 87 -47 20 -73 19 -123 -4z m121 -32 c8 -13 -16 -46 -42 -58 -20 -9 -28
-6 -55 19 -36 34 -33 41 19 50 41 7 70 3 78 -11z"/>
</g>
</svg>




    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
